<template>
  <div>
    <navigationBar :name="'会员列表'"></navigationBar>
    <div class="content">
      <md-field>
        <md-cell-item
          v-for="(item, index) in groupList"
          :key= index 
          :title="item.collage_name"
          :brief="item.collage_id"
          :addon="item.collage_type"
          arrow
        />
      </md-field>
    </div>
  </div>
</template>

<script>

import { Field, CellItem } from "mand-mobile";
import navigationBar from "@/components/navigationBar";
export default {
  name: "collageList",
  components: {
    [CellItem.name]: CellItem,
    [Field.name]: Field,
    navigationBar: navigationBar,
  },
  data() {
    return {
        groupList: []
    };
  },
  computed: {
    groupName() {
      return this.$route.params.groupName;
    },
  },
  watch: {
      groupName: {
          immediate: true,
          handler(data) {
              this.getCooperativeGroupList()
          }
      }
  },
  methods: {
      getCooperativeGroupList() {
          this.$API.cooperativeGroup.showCollage().then(data => {
              this.groupList = data.data.data
          })
      }
  }
};
</script>

<style scoped>
.content {
    margin: 0;
}
</style>
